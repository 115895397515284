<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="publicacionMunicialModal"
    tabindex="-1"
    aria-labelledby="publicacionMunicialModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{ dof.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <h6>Dependencia:</h6>
            <p>{{ dof.dependency }}</p>
          </div>
          <div>
            <h6>Tipo de documento:</h6>
            <p>{{ dof.document_type }}</p>
          </div>
          <div>
            <h6>Resumen:</h6>
            <p>{{ dof.resume }}</p>
          </div>
          <div>
            <h6>Fecha de publicación oficial:</h6>
            <p>{{ dof.fecha_publicacion_oficial_formatted }}</p>
          </div>
          <div>
            <h6>Fecha de aplicación:</h6>
            <p>
              {{
                dof.fecha_entrada_en_vigor
                  ? dof.fecha_entrada_en_vigor_formatted
                  : "NA"
              }}
            </p>
          </div>
          <div>
            <h6>Link:</h6>
            <p class="d-inline-block me-2">{{ dof.link }}</p>
            <a
              :href="dof.link"
              target="_blank"
              class="btn c-bg-primary text-white d-inline-block"
              >Ver</a
            >
          </div>
          <div>
            <h6>Comentarios:</h6>
            <p>{{ dof.comments }}</p>
          </div>
          <hr />
          <div v-html="dof.content"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { find } from "@/services/dofs";
export default {
  props: {
    publicacionId: {
      required: true,
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      dof: {},
    };
  },
  watch: {
    publicacionId() {
      this.dof = {};
      this.getPublicacion();
    },
  },
  mounted() {
    if (this.publicacionId) {
      this.getPublicacion();
    }
  },
  methods: {
    getPublicacion() {
      find(this.publicacionId).then((response) => (this.dof = response.data));
    },
  },
};
</script>
