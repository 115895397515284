<template>
  <div class="container">
    <publicacion-modal
      :publicacion-id="selectedPublicacionId"
    ></publicacion-modal>
    <show-paid-or-unpaid-data :paidInfo="false">
      <info>Con el plan Starter tienes acceso a las 10 últimas entradas</info>
    </show-paid-or-unpaid-data>
    <c-card title="Publicaciones del Diario Oficial de la Federación">
      <template v-slot:header-right>
        <div class="input-group mb-3 header-search">
          <input
            v-model="searchValue"
            type="text"
            class="form-control"
            @keyup="search"
          />
          <span class="input-group-text">
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
      </template>
      <div class="d-flex justify-content-end">
        <show-paid-or-unpaid-data>
          <json-excel :data="dofs" :fields="dofFields" name="dofs">
            <c-primary-button> Descargar </c-primary-button>
          </json-excel>
        </show-paid-or-unpaid-data>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Dependencia</th>
            <th>Resumen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dof in dofs" :key="dof.id">
            <td>{{ dof.title }}</td>
            <td>{{ dof.dependency }}</td>
            <td>{{ dof.resume }}</td>
            <td class="text-nowrap">
              <button
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                @click.prevent="selectedPublicacionId = dof.id"
                data-bs-toggle="modal"
                data-bs-target="#publicacionMunicialModal"
                title="Ver"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { showNotification } from "@/services/notification";
import { all } from "@/services/dofs";
import { userPlanIsActive } from "@/services/users";
import JsonExcel from "vue-json-excel3";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import ShowPaidOrUnpaidData from "@/components/showPaidOrUnpaidData";
import PublicacionModal from "@/components/Publicaciones/DofModal";
import Info from "@/components/Alerts/Info.vue";

export default {
  components: {
    Info,
    CCard,
    JsonExcel,
    CPrimaryButton,
    ShowPaidOrUnpaidData,
    PublicacionModal,
  },
  data() {
    return {
      userPlanName: "",
      dofs: [],
      selectedPublicacionId: null,
      timer: null,
      searchValue: "",
      dofFields: {
        "Fecha de publicación oficial": "fecha_publicacion_oficial_formatted",
        Dependencia: "dependency",
        Título: "title",
        "Tipo de documento": "document_type",
        Resumen: "resume",
        "Fecha de aplicación": "fecha_entrada_en_vigor_formatted",
        Comentarios: "comments",
        Link: "link",
      },
    };
  },
  mounted() {
    this.getDofs();
  },
  methods: {
    getDofs(params = {}) {
      all(params)
        .then(async (response) => {
          const planIsActive = await userPlanIsActive();
          if (!planIsActive) {
            // TODO refactor to handle this in backend
            this.dofs = response.data.slice(0, 10);
          } else {
            this.dofs = response.data;
          }
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error cargando la información. " + error
          );
        });
    },
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDofs({ title: this.searchValue });
      }, 800);
    },
  },
};
</script>
